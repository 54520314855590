/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React from 'react';

import { Breadcrumbs } from 'app/common/components';
import { ResultsHeader } from 'app/search-and-browse/shared/components';
import { ResultsLayoutContext } from 'app/search-and-browse/shared/contexts';
import TopBrandContext from 'app/common/contexts/TopBrandContext';
import TopBrandHeader from 'app/search-and-browse/shared/components/TopBrandHeader';

const BrowseHeader = ({ searchResponse, title }) => {
  const { breadcrumbs } = React.useContext(ResultsLayoutContext);
  const { activeTopBrand } = React.useContext(TopBrandContext);

  return (
    <header className="text-center">
      <Breadcrumbs breadcrumbs={breadcrumbs} />
      <TopBrandHeader activeTopBrand={activeTopBrand} />
      <ResultsHeader searchResponse={searchResponse} title={title} />
    </header>
  );
};

export default BrowseHeader;
export { BrowseHeader };
