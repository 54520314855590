import React from 'react';

const BrandList = ({ brandsMap }) => {
  return (
    <section className="mt-8">
      {Object.entries(brandsMap).map((entry, i) => (
        <BrandSection sectionKey={entry[0]} brandList={entry[1]} key={i} />
      ))}
    </section>
  );
};

const BrandSection = ({ sectionKey, brandList }) => {
  return (
    <>
      <div
        id={sectionKey}
        className={
          'flex justify-between border-solid border-2 border-blue-900 p-2 brands-section '
        }
      >
        <span className="brands-sectionKey text-primary-500 font-semibold text-lg">
          {sectionKey}
        </span>
        <span className="brands-back-to-top">
          <a href="#" className="text-primary-300 underline font-semibold">
            Back to Top
          </a>
        </span>
      </div>
      <div className={'brands-section-list grid gap-x-8 gap-y-4 mb-8 mt-8'}>
        {brandList.map((entry, i) => (
          <BrandLink entry={entry} key={i} />
        ))}
      </div>
    </>
  );
};

const BrandLink = ({ entry }) => {
  const { brandName, brandPath } = entry;
  return (
    <a
      href={`${brandPath}/part-search`}
      className={'text-primary-400 underline px-2'}
    >
      {brandName}
    </a>
  );
};

export default BrandList;
export { BrandList };
