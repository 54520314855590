/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/

import React from 'react';
import { get, merge, noop } from 'lodash';
import { CurrencyContext } from 'app/common/contexts';
import qs from 'query-string';
import { Environment } from 'app/common/services';

import {
  getDefaultCurrencyFromCache,
  putDefaultCurrencyInCache
} from './services/CurrencyCache';
import { withRouter } from 'react-router-dom';

const BL_CURRENCY_PARAM = Environment.get('CURRENCY_PARAM', 'blCurrency');

const CurrencyProvider = ({
  defaultAllowedCurrencies,
  children,
  defaultCurrency,
  location,
  history
}) => {
  defaultCurrency = defaultCurrency || getDefaultCurrency(location);
  const search = get(location, 'search');
  const historyPush = get(history, 'replace', noop);
  const [currentCurrency, setCurrentCurrency] = React.useState(defaultCurrency);
  const [allowedCurrencies, setAllowedCurrencies] = React.useState(
    defaultAllowedCurrencies
  );

  const setCurrentCurrencyCallback = React.useCallback(
    currency => {
      const params = merge({}, qs.parse(search), {
        [BL_CURRENCY_PARAM]: currency
      });
      historyPush({ search: qs.stringify(params) });
      putDefaultCurrencyInCache(currency);
      return setCurrentCurrency(currency);
    },
    [historyPush, search]
  );

  const context = React.useMemo(() => {
    return {
      allowedCurrencies,
      currentCurrency,
      setAllowedCurrencies,
      setCurrentCurrency: setCurrentCurrencyCallback
    };
  }, [allowedCurrencies, currentCurrency, setCurrentCurrencyCallback]);

  return (
    <CurrencyContext.Provider value={context}>
      {children}
    </CurrencyContext.Provider>
  );
};

CurrencyProvider.defaultProps = {
  defaultAllowedCurrencies: Environment.get('ALLOWED_CURRENCIES', 'USD').split(
    ','
  )
};

function getDefaultCurrency(location = {}) {
  // first look to see if the user has a manual override as a query parameter
  const currencyFromLocation =
    !!location.search && qs.parse(location.search)[BL_CURRENCY_PARAM];

  if (!!currencyFromLocation) {
    putDefaultCurrencyInCache(currencyFromLocation);
    return currencyFromLocation;
  }

  // second, look at the last currency cached based on a query parameter
  const cachedCurrency = getDefaultCurrencyFromCache();

  if (!!cachedCurrency) {
    return cachedCurrency;
  }

  // lastly default to english
  return Environment.get('DEFAULT_CURRENCY', 'USD');
}

export default withRouter(CurrencyProvider);
export { CurrencyProvider, getDefaultCurrency };
