/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React, { useEffect } from 'react';
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  withRouter
} from 'react-router-dom';

import { About } from 'app/about/components';
import { Contact } from 'app/contact/components';
import { Privacy } from 'app/privacy/components';
import { TermsOfUse } from 'app/tou/components';
import { Register } from 'app/auth/components';
import { AuthContext } from 'app/auth/contexts';
import { AuthService } from 'app/auth/services';
import { Cart } from 'app/cart/components';
import { OrderTracking } from 'app/common/components';
import { ResolutionContextType } from 'app/common/constants';
import { CampaignContext, TenantContext } from 'app/common/contexts';
import {
  useCatalogInfo,
  useFormatMessage,
  useMenuInfo,
  useScrollToTop
} from 'app/common/hooks';
import { PrivateRoute, RouteStatus } from 'app/core/components';
import { CSRRibbon, CSRTransferAnonymousCart } from 'app/csr/components';
import { Homepage } from 'app/homepage/components';
import { Layout, LayoutSkeleton } from 'app/my-account/dashboard/components';
import { ProductRenderer } from 'app/product/helpers';
import { SearchResultsPage } from 'app/search-and-browse/search/components';
import { CategoryRenderer } from 'app/search-and-browse/category/helpers';

import NotFound from '../NotFound';
import Footer from '../Footer';
import Header from '../Header';
import messages from './BaseLayout.messages';
import { StoreLocation } from 'app/store-location/components';
import EmbeddedCampaign from 'app/core/components/EmbeddedCampaign';
import { Brands } from 'app/brands/components/index.js';

/**
 * React component that wraps the given `children` with basic layout
 * components like the header and footer.
 *
 * @visibleName Base Layout
 * @author [Nathan Moore](https://github.com/nathandmoore)
 */
const BaseLayout = props => {
  const {
    location: { pathname }
  } = props;
  const { footerMenuName } = useMenuInfo();
  const { categoryInfo, productInfo } = useCatalogInfo();
  const { routeBaseContext: categoryPrefix } = categoryInfo;
  const { routeBaseContext: productPrefix } = productInfo;
  const { resolutionContext } = React.useContext(TenantContext);
  useScrollToTop([pathname]);
  const targetLocation = useCampaignLocation(props);

  return (
    <div className="relative flex flex-col min-h-screen">
      <header className="sticky top-0 z-20 bg-white">
        <CSRRibbon />
        <Header />
      </header>
      <div className="flex flex-col flex-grow">
        <Switch location={targetLocation}>
          <Route exact path="/callback" component={ProcessingAuthCallback} />
          <Route exact path="/login-with-redirect" component={LoginRedirect} />
          <Route exact path="/" component={Homepage} />
          <Route
            path="/brand/:brandname/search"
            component={SearchResultsPage}
          />
          <Route exact path="/search" component={SearchResultsPage} />
          <Route exact path="/cart" component={Cart} />
          <Route
            exact
            path="/csr/transfer-cart"
            component={CSRTransferAnonymousCart}
          />
          <Route exact path={[categoryPrefix]} component={CategoryRenderer} />
          <Route
            path={[
              `${categoryPrefix}/:category`,
              `/brand/:brandname${categoryPrefix}/:category`,
              `/brand/:brandname${categoryPrefix}`
            ]}
            component={CategoryRenderer}
          />
          <Route path={'/brands'} component={Brands} />
          <Route
            path={`${productPrefix}/:product`}
            component={ProductRenderer}
          />
          <Route exact path="/register" component={Register} />
          <Route exact path="/order-tracking" component={OrderTracking} />
          <Route
            path="/order-tracking/:orderNumber"
            component={OrderTracking}
          />
          <PrivateRoute
            path="/my-account"
            component={Layout}
            skeletonComponent={LayoutSkeleton}
          />
          {resolutionContext !== ResolutionContextType.DEALER && (
            <Route path="/store-location" component={StoreLocation} />
          )}
          {resolutionContext !== ResolutionContextType.DEALER && (
            <Route
              path={[
                '/about/:accountNumber/:locationId',
                '/about-us/:accountNumber/:locationId'
              ]}
              component={About}
            />
          )}
          <Route path={['/about', '/about-us']} component={About} />
          <Route
            path={['/tou', '/terms', '/terms-of-use']}
            component={TermsOfUse}
          />
          <Route path={['/privacy', '/privacy-policy']} component={Privacy} />
          <Route path={['/contact', '/contact-us']} component={Contact} />
          <Route path={['/_cp/*']} component={CampaignView} />
          {/* 404/NotFound must be LAST */}
          <Route component={NotFound} />
        </Switch>
      </div>
      <Footer menuName={footerMenuName} />
      <EmbeddedCampaign />
    </div>
  );
};

const ProcessingAuthCallback = () => {
  const formatMessage = useFormatMessage();
  return (
    <div className="relative flex flex-col items-center flex-grow my-12 mx-6">
      <RouteStatus
        className="shadow w-full max-w-screen-3/4"
        isLoading
        headerColor="bg-gray-100"
        title={formatMessage(messages.verifyAuthentication)}
      />
    </div>
  );
};

const LoginRedirect = () => {
  const { isAuthenticated } = React.useContext(AuthContext);

  React.useEffect(() => {
    if (!isAuthenticated) {
      AuthService.loginWithRedirect();
    }
  }, [isAuthenticated]);

  if (isAuthenticated) {
    return <Redirect to="/" />;
  }

  return <div />;
};

/**
 * If campaign view and campaign has a target page, this sets the history
 * to the target page
 */
const CampaignView = () => {
  const { loading, targetPage, fullUrl, isCampaignFound, id } =
    React.useContext(CampaignContext);
  const routerHistory = useHistory();
  useEffect(() => {
    if (!loading && isCampaignFound && targetPage && id) {
      routerHistory.push(fullUrl);
    }
    if (!loading && !isCampaignFound) {
      // If no campaign here, render 404
      routerHistory.push('/not-found');
    }
  }, [loading, targetPage, fullUrl, routerHistory, isCampaignFound, id]);

  return <></>;
};

function useCampaignLocation(props) {
  const { location } = props;
  const { pathname } = location;
  const { targetPage, loading, isCampaignFound, id, fullUrl } =
    React.useContext(CampaignContext);
  let targetLocation = location;
  const isCampaignUrl =
    !loading && isCampaignFound && targetPage && id && fullUrl === pathname;
  if (isCampaignUrl) {
    targetLocation = {
      ...location,
      pathname: targetPage,
      state: {
        campaignId: id
      }
    };
  }
  return targetLocation;
}

export default withRouter(BaseLayout);
export { BaseLayout };
