/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { filter, get, isNumber, map, size, toNumber } from 'lodash';
import PropTypes from 'prop-types';
import AnimateHeight from 'react-animate-height';
import { withRouter } from 'react-router-dom';

import {
  Button,
  Dropdown,
  Icon,
  Input,
  SecondaryButton,
  TertiaryButton
} from 'app/common/components';
import { useFormatMessage, useFormatNumber, useToggle } from 'app/common/hooks';
import {
  parseSearch,
  stringifyParams
} from 'app/search-and-browse/shared/utils/FilterUtils';

import { FilterContext } from '../../contexts';
import ResultsFilterValue from '../ResultsFilterValue';
import { toggleCurrentFilter } from './utils/FilterUtils';
import messages from './ResultsFilter.messages';
import { Environment } from 'app/common/services/index.js';

const minValuesRequiredForSearch = toNumber(
  Environment.get('SEARCHABLE_FACETS_DISPLAY_THRESHOLD', '10')
);
const searchableFacetsEnabled =
  Environment.get('SEARCHABLE_FACETS_ENABLED', 'true') === 'true';

/**
 * Render component for a Filter/Facet and its values.
 *
 * @visibleName Browse and Search Results Filter
 * @author [Nathan Moore](https://github.com/nathandmoore)
 */
const ResultsFilter = props => {
  const {
    expandedByDefault = true,
    facet,
    handleChange,
    hidden,
    history,
    values,
    isSearchable,
    selectedFirst
  } = props;
  const searchable = isSearchable && isSearchableFacet(values);

  const { lockFilters, setLockFilters } = React.useContext(FilterContext);
  const { label, name, multiSelect = true, ranged = false } = facet;
  const [isActive, toggleActive] = useToggle(expandedByDefault);

  const [filteredValues, setFilteredValues] = useState(values);
  const [query, setQuery] = useState('');
  useFilteredValues({
    values,
    setFilteredValues,
    query,
    setQuery,
    searchable,
    selectedFirst
  });

  return (
    <li className="inline lg:block lg:mb-4">
      <MobileFilter
        {...props}
        isActive={isActive}
        history={history}
        toggleActive={toggleActive}
        filteredValues={filteredValues}
        setFilteredValues={setFilteredValues}
        query={query}
        setQuery={setQuery}
      />
      <div
        className={classNames(
          'hidden flex-1 last:mb-0 lg:block lg:flex-initial lg:basis-auto lg:mb-4',
          { hidden: hidden }
        )}
      >
        <Button
          aria-expanded={isActive}
          className="flex items-center justify-between w-full mb-2 pb-1 text-center capitalize font-bold border-b border-gray-400 text-gray-700 appearance-none hover:text-gray-900 focus:outline-none active:bg-transparent last:mb-0"
          onClick={() => {
            toggleActive();
          }}
        >
          <span>{label}</span>
          {!isActive && <Icon name="plus" size="xs" />}
          {isActive && <Icon name="minus" size="xs" />}
        </Button>

        <AnimateHeight duration={200} height={isActive ? 'auto' : 0}>
          {searchable && (
            <div className={'pl-2'}>
              <Input
                name={facet.name}
                value={query}
                onChange={e => setQuery(e.target.value)}
                size={Input.Size.SMALL}
              />
            </div>
          )}
          <ol className="flex flex-col list-none" aria-hidden={!isActive}>
            {map(filteredValues, (value, i) => {
              const maxValueNum = parseNumber(value.maxValue);
              const minValueNum = parseNumber(value.minValue);
              if (value.active) console.log('value', value);
              return (
                <ResultsFilterValue
                  {...value}
                  handleChange={(e, valueToUse) => {
                    if (lockFilters) {
                      return false;
                    }

                    setLockFilters(true);

                    const { location, push } = history;
                    const params = parseSearch(location.search);
                    const filters = get(params, 'filters', []);

                    const changeProps = {
                      checked: e.target.checked,
                      event: e,
                      filters,
                      maxValue: maxValueNum,
                      minValue: minValueNum,
                      multiSelect,
                      name,
                      ranged,
                      value: valueToUse
                    };
                    if (!!handleChange) {
                      const stop = !handleChange(changeProps);

                      if (stop) {
                        return;
                      }
                    }

                    params.filters = toggleCurrentFilter(changeProps);
                    // need to reset the page number when changing facets so we don't end up at a nonexistent page
                    params.page = 1;

                    push({
                      ...location,
                      search: stringifyParams(params)
                    });

                    setLockFilters(false);
                  }}
                  key={`${name}-value-${i}`}
                />
              );
            })}
          </ol>
        </AnimateHeight>
      </div>
    </li>
  );
};

const MobileFilter = ({
  facet: { label, name, multiSelect = true, ranged = false },
  handleChange,
  hidden,
  history,
  filteredValues,
  query,
  setQuery
}) => {
  const values = filteredValues;
  const { lockFilters, setLockFilters } = React.useContext(FilterContext);
  const [filters, setFilters] = React.useState(
    get(parseSearch(history.location.search), 'filters', [])
  );
  const numActiveValues = size(filter(values, 'active'));
  const formatNumber = useFormatNumber();
  const formatMessage = useFormatMessage();

  return (
    <div className={classNames('inline-block mr-2 p-px lg:hidden', { hidden })}>
      <Dropdown
        className={classNames('inline-block w-full mr-2 p-px lg:hidden', {
          hidden
        })}
      >
        <Dropdown.Menu.Trigger
          className="w-full"
          triggerComponent={MobileTrigger}
          active={numActiveValues > 0}
        >
          {({ isOpen }) => (
            <>
              <span className="mr-1">{`${label}${
                numActiveValues > 0 ? ` (${formatNumber(numActiveValues)})` : ''
              }`}</span>
              {!isOpen && (
                <Icon className="pointer-events-none" name="plus" size="xs" />
              )}
              {isOpen && (
                <Icon className="pointer-events-none" name="minus" size="xs" />
              )}
            </>
          )}
        </Dropdown.Menu.Trigger>
        <Dropdown.Menu
          containerClassName="static"
          className="top-full left-0 flex flex-wrap w-full mt-px pt-3 bg-white overflow-y-scroll"
          openTo={Dropdown.Menu.OpenTo.CUSTOM}
          roundness="rounded-b"
          style={{ maxHeight: '50vh' }}
        >
          {map(values, (value, i) => {
            const maxValueNum = parseNumber(value.maxValue);
            const minValueNum = parseNumber(value.minValue);

            return (
              <ResultsFilterValue
                {...value}
                handleChange={(e, valueToUse) => {
                  if (lockFilters) {
                    return false;
                  }

                  setLockFilters(true);

                  const changeProps = {
                    checked: e.target.checked,
                    event: e,
                    filters,
                    maxValue: maxValueNum,
                    minValue: minValueNum,
                    multiSelect,
                    name,
                    ranged,
                    value: valueToUse
                  };

                  if (!!handleChange) {
                    handleChange(changeProps);
                  }

                  const newFilters = toggleCurrentFilter(changeProps);

                  setFilters(newFilters);

                  setLockFilters(false);
                }}
                key={`${name}-value-${i}`}
                multiSelect={multiSelect}
                name={name}
                ranged={ranged}
              />
            );
          })}
          <li className="sticky bottom-0 flex justify-start basis-1/2 mt-2 p-2 bg-white border-t border-gray-300">
            <TertiaryButton
              className="px-3 py-1"
              onClick={() => {
                if (lockFilters) {
                  return;
                }

                setLockFilters(true);

                const { location, push } = history;
                const params = parseSearch(location.search);
                params.filters = filter(params.filters, f => f.name !== name);
                // need to reset the page number when changing facets so we don't end up at a nonexistent page
                params.page = 1;

                push({
                  ...location,
                  search: stringifyParams(params)
                });

                setLockFilters(false);
              }}
              disabled={lockFilters}
            >
              {formatMessage(messages.mobileResetFilters)}
            </TertiaryButton>
          </li>
          <li className="sticky bottom-0 flex justify-end basis-1/2 mt-2 p-2 bg-white border-t border-gray-300">
            <TertiaryButton
              className="px-3 py-1"
              onClick={() => {
                if (lockFilters) {
                  return;
                }

                setLockFilters(true);

                const { location, push } = history;
                const params = parseSearch(location.search);
                params.filters = filters;
                // need to reset the page number when changing facets so we don't end up at a nonexistent page
                params.page = 1;

                push({
                  ...location,
                  search: stringifyParams(params)
                });

                setLockFilters(false);
              }}
              disabled={lockFilters}
            >
              {formatMessage(messages.mobileSubmitFilters)}
            </TertiaryButton>
          </li>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};

const MobileTrigger = ({
  children,
  className,
  handleTrigger,
  isOpen,
  label,
  active,
  ...rest
}) => {
  return (
    <SecondaryButton
      aria-expanded={isOpen}
      aria-haspopup="true"
      backgroundColor={
        active
          ? 'bg-primary-100 hover:bg-primary-200 focus:bg-primary-200 active:bg-white disabled:bg-white'
          : undefined
      }
      borderColor={
        active
          ? 'border-primary-200 hover:border-primary-300 focus:border-primary-300 disabled:border-gray-200'
          : undefined
      }
      className="flex items-center justify-between w-full"
      onClick={e => handleTrigger(e, !isOpen)}
      size={SecondaryButton.Size.SMALL}
      {...rest}
    >
      {children({ isOpen })}
    </SecondaryButton>
  );
};

function useFilteredValues({
  values,
  setFilteredValues,
  query,
  searchable,
  selectedFirst
}) {
  useEffect(() => {
    if (!searchable) {
      return;
    }
    const q = query ? query.toLowerCase().trim() : '';
    let filtered = values.filter(v => {
      let val = v.value instanceof String ? v.value : v.value.toString();
      return v.active || val.toLowerCase().includes(q);
    });

    if (selectedFirst) {
      // sort by active AND alphabetical at the top
      filtered = filtered.sort((a, b) => {
        if (a.active && !b.active) {
          return -1;
        }
        if (!a.active && b.active) {
          return 1;
        }
        if (a.value < b.value) {
          return -1;
        }
        if (a.value > b.value) {
          return 1;
        }
        return 0;
      });
    }

    setFilteredValues(filtered);
  }, [searchable, query, values, selectedFirst, setFilteredValues]);
}

function isSearchableFacet(values) {
  return searchableFacetsEnabled && values.length > minValuesRequiredForSearch;
}

function parseNumber(value) {
  return isNumber(value) ? toNumber(value) : value;
}

ResultsFilter.propTypes = {
  hidden: PropTypes.bool,
  expandedByDefault: PropTypes.bool,
  /** General info about the filter */
  facet: PropTypes.shape({
    label: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    /** Whether multiple values can be selected at once */
    multiSelect: PropTypes.bool,
    /** Whether the filter uses a range demarcations rather than discrete values */
    ranged: PropTypes.bool
  }).isRequired,
  /**
   * Handler to use when a filter value changes instead of using the default
   */
  handleChange: PropTypes.func,
  history: PropTypes.shape({
    location: PropTypes.shape({
      pathname: PropTypes.string,
      search: PropTypes.string,
      hash: PropTypes.string,
      state: PropTypes.object
    }).isRequired,
    push: PropTypes.func.isRequired
  }).isRequired,
  /** Possible values for the filter to display */
  values: PropTypes.arrayOf(
    PropTypes.shape({
      /** Whether this value is currently selected */
      active: PropTypes.bool,
      /**
       * If this value belongs to a ranged Filter, then this is the max value for
       * this segment of the range.
       */
      maxValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      /**
       * If this value belongs to a ranged Filter, then this is the min value for
       * this segment of the range.
       */
      minValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      /** The number of items in the result set that match this value */
      quantity: PropTypes.number,
      /** If not ranged, then this is the explicit value */
      value: PropTypes.string
    })
  ).isRequired,
  /** Is this a searchable filterable value? */
  isSearchable: PropTypes.bool
};

export default withRouter(ResultsFilter);
export { ResultsFilter };
