/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import { HTMLWidget } from 'app/common/components';
import React, { useReducer } from 'react';
import { get, isEmpty } from 'lodash';
import { Helmet as ReactHelmet } from 'react-helmet';

import { AboutDisplayGroup } from 'app/about/components/About/components';
import {
  useFormatMessage,
  useGtmPageView,
  useNationalSiteContext,
  usePersonalizationInfo
} from 'app/common/hooks';
import { useHeaderMetadata } from 'app/core/components/App';
import { TargetedContentRenderer } from 'app/personalization/helpers';

import messages from './About.messages';
import { useLocation } from 'react-router-dom';
import {
  useAccountAndLocationNumber,
  useDetermineDealerForDisplay
} from 'app/about/components/About/hooks';
import {
  aboutUsInitialState,
  aboutUsReducer
} from 'app/about/components/About/reducer/aboutUsReducer';
import { ResolutionContextType } from 'app/common/constants';

const About = () => {
  const { accountNumber, locationId } = useAccountAndLocationNumber();
  const {
    dealerSelected,
    resolving,
    resolutionContext,
    isWhiteLabel,
    applicationThemeSource
  } = useNationalSiteContext();
  const [state, dispatch] = useReducer(aboutUsReducer, {
    ...aboutUsInitialState,
    accountNumber,
    locationId
  });
  useDetermineDealerForDisplay(state, dispatch);

  const { storeAddressHoursSource } = state;

  if (isEmpty(storeAddressHoursSource) || resolving) {
    return null;
  }
  const isDealerWhitelabel = isDealerWhitelabelContext(
    isWhiteLabel,
    resolutionContext
  );

  return (
    <main className="container relative flex-grow mx-auto p-4 sm:py-8 md:px-6 lg:px-8">
      <AboutUsHelmet />
      {isWhiteLabel ? (
        <BrandedContent
          dealerSelected={dealerSelected}
          storeAddressHoursSource={storeAddressHoursSource}
          aboutContentSource={applicationThemeSource}
          isDealerWhitelabel={isDealerWhitelabel}
        />
      ) : (
        <PartsToGoContent
          dealerSelected={dealerSelected}
          storeAddressHoursSource={storeAddressHoursSource}
        />
      )}
    </main>
  );
};

function isDealerWhitelabelContext(isWhiteLabel, resolutionContext) {
  return isWhiteLabel && resolutionContext === ResolutionContextType.DEALER;
}

/**
 * Shown for PTG.
 * @param dealerSelected
 * @param storeAddressHoursSource
 * @return {Element}
 * @constructor
 */
const PartsToGoContent = ({ storeAddressHoursSource, dealerSelected }) => {
  const personalizationInfo = usePersonalizationInfo();
  const targeterName = get(personalizationInfo, 'about.targeterName');
  const formatMessage = useFormatMessage();
  const aboutUsTitle = TargetedContentRenderer({
    name: targeterName,
    contextAttributes: AboutTitleContext
  });
  const showAboutDisplayGroup =
    !isEmpty(storeAddressHoursSource) && dealerSelected;

  return (
    <>
      <header className="mb-8 pb-4 border-b border-gray-400">
        <h1 className="text-xl leading-none md:text-2xl lg:text-3xl">
          {aboutUsTitle || formatMessage(messages.title)}
        </h1>
      </header>
      {showAboutDisplayGroup && (
        <AboutDisplayGroup application={storeAddressHoursSource} />
      )}
      <DefaultAboutUsContent />
    </>
  );
};

/**
 * Shown for BNS, DN, and Dealer Whitelabel.
 * @param dealerSelected Is a dealer selected?
 * @param storeAddressHoursSource Application to use when displaying store hours and address.
 * @param aboutContentSource Application to use when displaying about content.
 * @return {React.JSX.Element|null}
 * @constructor
 */
const BrandedContent = ({
  dealerSelected,
  storeAddressHoursSource,
  aboutContentSource
}) => {
  const formatMessage = useFormatMessage();
  if (isEmpty(aboutContentSource)) {
    return null;
  }

  const aboutTitle = isEmpty(aboutContentSource?.aboutSectionTitle)
    ? formatMessage(messages.title)
    : aboutContentSource.aboutSectionTitle;
  return (
    <>
      <h1 className="text-2xl font-bold mb-4">{aboutTitle}</h1>
      {dealerSelected && (
        <AboutDisplayGroup application={storeAddressHoursSource} />
      )}
      <BrandedAboutUsContent aboutContentSource={aboutContentSource} />
    </>
  );
};

const BrandedAboutUsContent = ({ aboutContentSource }) => {
  const hasContent =
    !isEmpty(aboutContentSource) && !isEmpty(aboutContentSource.aboutSection1);
  const hasContent2 =
    !isEmpty(aboutContentSource) && !isEmpty(aboutContentSource.aboutSection2);

  if (hasContent) {
    return (
      <>
        <section className="mb-4 whitespace-pre-wrap">
          <HTMLWidget content={aboutContentSource.aboutSection1} />
        </section>
        {hasContent2 && (
          <section className="pt-4 border-t-2 border-solid border-gray-400 whitespace-pre-wrap">
            <HTMLWidget content={aboutContentSource.aboutSection2} />
          </section>
        )}
      </>
    );
  }
  return <DefaultAboutUsContent />;
};

const DefaultAboutUsContent = () => {
  const personalizationInfo = usePersonalizationInfo();
  const targeterName = get(personalizationInfo, 'about.targeterName');

  return (
    <>
      <TargetedContentRenderer
        name={targeterName}
        contextAttributes={TopSectionContext}
      />
      <TargetedContentRenderer
        name={targeterName}
        contextAttributes={BottomSectionContext}
      />
    </>
  );
};

const AboutUsHelmet = () => {
  const formatMessage = useFormatMessage();
  const { path } = useLocation();
  const { siteTitle, siteDescription } = useHeaderMetadata();
  const { isStoreDetailsPath } = useAccountAndLocationNumber();

  const title = formatMessage(messages.title);
  const canonical = `${window.location.origin}${path}`;
  useGtmPageView(isStoreDetailsPath ? 'About Us' : 'Store Details About Us');

  return (
    <ReactHelmet titleTemplate={`%s - ${siteTitle}`}>
      <title>{title}</title>
      <meta name="description" content={formatMessage(messages.description)} />
      <meta name="canonical" content={canonical} />
      <meta name="robots" content="index,follow" />
      <meta property="og:title" content={title} />
      <meta property="og:site_name" content={siteTitle} />
      <meta property="og:type" content="website" />
      <meta property="og:description" content={siteDescription} />
      <link rel="canonical" href={canonical} />
    </ReactHelmet>
  );
};

const AboutTitleContext = Object.freeze({ zone: { name: 'TITLE' } });
const TopSectionContext = Object.freeze({ zone: { name: 'TOP' } });
const BottomSectionContext = Object.freeze({ zone: { name: 'BOTTOM' } });

export default About;
export { About };
