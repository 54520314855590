import { useRestApi, useToggle } from 'app/common/hooks/index.js';
import { Environment } from 'app/common/services/index.js';
import { useEffect } from 'react';
import { logError } from 'app/common/utils/ApiErrorUtils/index.js';
import { get, isEmpty } from 'lodash';

function useAllBrandsApi(setter) {
  const [shouldFetch, toggleShouldFetch] = useToggle(true);
  const [shouldSet, toggleShouldSet] = useToggle(true);

  const {
    error,
    exception,
    response,
    sendCallback: fetchAllBrands,
    requestStatus
  } = useRestApi(getMinBrandsUrl(), {}, false);

  useEffect(() => {
    if (!shouldFetch && !shouldSet) {
      return;
    }

    if (error) {
      logError({ ...exception, when: 'Fetching Brands' });
      return;
    }

    if (!isEmpty(response) && shouldSet) {
      setter(get(response, 'brands'));
      toggleShouldSet(false);
      return;
    }

    if (shouldFetch) {
      fetchAllBrands();
      toggleShouldFetch(false);
    }
  }, [
    error,
    exception,
    response,
    requestStatus,
    setter,
    fetchAllBrands,
    shouldFetch,
    toggleShouldFetch,
    shouldSet,
    toggleShouldSet
  ]);
}

function getMinBrandsUrl() {
  return Environment.get(
    'brands.min.url',
    'catalog-browse/top-brands/all-brands'
  );
}

export default useAllBrandsApi;
