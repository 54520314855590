import {
  useCatalogInfo,
  usePriceContextHeader,
  usePriceInfoContextHeader,
  useRestApi
} from 'app/common/hooks';
import { useEffect } from 'react';
import { isEmpty, merge } from 'lodash';
import { logError } from '../../../utils/ApiErrorUtils';
import * as RequestStatus from '../../useRestApi/RequestStatus';

function useProductPricingApi(items) {
  const priceContextHeader = usePriceContextHeader();
  const priceInfoContextHeader = usePriceInfoContextHeader();
  const {
    productInfo: { priceUri, fetchUrl }
  } = useCatalogInfo();
  const productPriceUri = `${fetchUrl}${priceUri}`;

  const {
    exception,
    error,
    response,
    requestStatus,
    sendCallback: fetchPricing
  } = useRestApi(productPriceUri, {}, false);

  useEffect(() => {
    if (requestStatus !== RequestStatus.PENDING || isEmpty(items)) {
      return;
    }

    const itemIds = items.map(item => item.id).join(',');

    const config = merge({}, priceContextHeader, priceInfoContextHeader, {
      params: { productIds: itemIds },
      timeout: 4000
    });

    fetchPricing(config);
  }, [
    items,
    requestStatus,
    fetchPricing,
    priceContextHeader,
    priceInfoContextHeader
  ]);

  const defaultPrices = {};
  if (error) {
    const defaultPricesArr = items.map(item => ({
      [item.id]: {
        productId: item.id,
        priceInfo: item.priceInfo
      }
    }));

    for (let obj of defaultPricesArr) {
      merge(defaultPrices, obj);
    }

    logError({
      ...exception,
      when: 'fetching prices for product'
    });
  }

  return isEmpty(response) ? defaultPrices : response;
}

export default useProductPricingApi;
