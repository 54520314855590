import { withRouter } from 'react-router-dom';
import { CheckoutContext } from 'app/checkout/contexts';
import React, { useContext, useEffect } from 'react';
import {
  mapFulfillmentOptionsResponse,
  useFetchFulfillmentOptions
} from 'app/checkout/components/CheckoutLayout/components/FulfillmentInfoWithOptions/hooks/useFulfillmentOptions';
import { CartContext } from 'app/common/contexts';
import * as RequestStatus from 'app/common/hooks/useRestApi/RequestStatus';
import {
  setFetchDeliveryOptions,
  setFetchDeliveryOptionsFailed,
  setFulfillmentOptions
} from 'app/checkout/contexts/reducers/checkoutReducer';
import { get, isEmpty } from 'lodash';
import {
  DeliveryOptions,
  DeliveryOptionsReadOnly
} from 'app/checkout/components/CheckoutLayout/components/FulfillmentInfoWithOptions/components';
import messages from 'app/checkout/components/CheckoutLayout/components/FulfillmentInfoWithOptions/FulfillmentInfoWithOptions.messages';
import { Stage } from 'app/checkout/elements';
import { useFormatMessage, useFulfillmentInfoApi } from 'app/common/hooks';
import { Form, Formik } from 'formik';
import goToNextCheckoutStep from 'app/checkout/components/CheckoutLayout/components/hooks/useGoToNextStep';
import { PrimaryButton, SecondaryButton } from 'app/common/components';
import { REVIEW_PATH } from 'app/checkout/components/CheckoutLayout/CheckoutPaths';
import GeneralFormError from 'app/checkout/components/CheckoutLayout/components/FulfillmentInfoWithOptions/components/GeneralFormError';

const FulfillmentOptionsSection = ({
  active,
  stageNumber = 3,
  nextStepPath,
  history
}) => {
  const formatMessage = useFormatMessage();
  const { checkoutDispatch, deliveryOptionsStale, fulfillmentOptions } =
    useContext(CheckoutContext);
  const { cart } = useContext(CartContext);
  const pricedFulfillmentOption = get(
    cart,
    'fulfillmentGroups[0].pricedFulfillmentOption'
  );
  useFetchOptions({ active });
  useEffect(() => {
    if (deliveryOptionsStale || isEmpty(fulfillmentOptions)) {
      setFetchDeliveryOptions(checkoutDispatch, true);
    }
    // eslint-disable-next-line
  }, [deliveryOptionsStale, fulfillmentOptions]);
  const goToNextStep = goToNextCheckoutStep(history, nextStepPath, {
    editing: false
  });
  return (
    <Stage
      active={active}
      completed={!isEmpty(pricedFulfillmentOption)}
      heading={formatMessage(messages.deliveryTitle)}
      number={stageNumber}
    >
      <DeliveryOptionsSection
        active={active}
        goToNextStep={goToNextStep}
        history={history}
      />
    </Stage>
  );
};

const DeliveryOptionsSection = ({ active, goToNextStep, history }) => {
  const deliveryOptionsSubmitValues = useGetSubmitValues();
  const { fulfillmentOptions, completed, fetchDeliveryOptionsFailed } =
    deliveryOptionsSubmitValues;
  const { cart } = useContext(CartContext);
  const selectedFulfillmentOption = get(
    cart,
    'fulfillmentGroups[0].pricedFulfillmentOption.serviceLevel',
    undefined
  );
  const formatMessage = useFormatMessage();
  const goToReviewStep = goToNextCheckoutStep(history, REVIEW_PATH, {
    editing: false
  });
  if (active && fulfillmentOptions && fulfillmentOptions.length > 0) {
    return (
      <Formik
        initialValues={{
          fulfillmentDeliveryOption: selectedFulfillmentOption
        }}
        onSubmit={submitFulfillmentOption({
          ...deliveryOptionsSubmitValues,
          goToNextStep
        })}
      >
        {({ isSubmitting, values }) => (
          <Form className={'flex flex-col'}>
            <GeneralFormError
              error={fetchDeliveryOptionsFailed}
              isSubmitting={isSubmitting}
              errorMessage={messages.fetchDeliveryOptionsFailed}
            />
            <DeliveryOptions />
            <div className="flex justify-end mt-2">
              {completed && (
                <SecondaryButton
                  className="mr-2"
                  disabled={isSubmitting || !active}
                  onClick={goToReviewStep}
                >
                  {formatMessage(messages.cancel)}
                </SecondaryButton>
              )}
              <PrimaryButton type="submit" disabled={isSubmitting || !active}>
                {formatMessage(messages.submit)}
              </PrimaryButton>
            </div>
          </Form>
        )}
      </Formik>
    );
  } else if (
    isEmpty(fulfillmentOptions) &&
    isEmpty(selectedFulfillmentOption)
  ) {
    return null;
  } else {
    return <DeliveryOptionsReadOnly />;
  }
};

/**
 * Return the values required for form submit
 */
const useGetSubmitValues = function () {
  const { cart, setCart, resolving } = useContext(CartContext);
  const {
    checkoutDispatch,
    fulfillmentOptions,
    selectedFulfillmentOption,
    failedOptions,
    fetchDeliveryOptionsFailed
  } = useContext(CheckoutContext);
  const fulfillmentGroup = get(cart, 'fulfillmentGroups[0]', {});
  const {
    error: updateFulfillmentError,
    sendCallback: updateFulfillmentGroup
  } = useFulfillmentInfoApi(cart.id, fulfillmentGroup.referenceNumber);
  const completed = !isEmpty(get(fulfillmentGroup, 'pricedFulfillmentOption'));
  return {
    cart,
    setCart,
    resolving,
    checkoutDispatch,
    fulfillmentOptions,
    fulfillmentGroup,
    updateFulfillmentError,
    updateFulfillmentGroup,
    completed,
    selectedFulfillmentOption,
    failedOptions,
    fetchDeliveryOptionsFailed
  };
};

const useFetchOptions = function ({ active }) {
  const { cart, resolving } = useContext(CartContext);
  const { checkoutDispatch, fetchDeliveryOptions, fulfillmentOptions } =
    useContext(CheckoutContext);
  const { sendCallback, error, requestStatus, response, exception } =
    useFetchFulfillmentOptions(cart || {}, false);
  useEffect(() => {
    const shouldNotFetch =
      !active || !fetchDeliveryOptions || !cart || resolving;
    if (shouldNotFetch) {
      // do nothing
    } else if (requestStatus !== RequestStatus.IN_PROGRESS) {
      setFetchDeliveryOptions(checkoutDispatch, false);
      sendCallback();
    }
  }, [
    cart,
    resolving,
    sendCallback,
    requestStatus,
    fetchDeliveryOptions,
    checkoutDispatch,
    fulfillmentOptions,
    active
  ]);

  useEffect(() => {
    if (response && requestStatus === RequestStatus.COMPLETE) {
      const mappedOptions = mapFulfillmentOptionsResponse(response);
      setFulfillmentOptions(checkoutDispatch, mappedOptions);
    }
  }, [response, requestStatus, checkoutDispatch]);

  useEffect(() => {
    if (!error) {
      return;
    }
    setFetchDeliveryOptionsFailed(checkoutDispatch, true);
    // eslint-disable-next-line
  }, [error, exception]);
};

function submitFulfillmentOption({
  cart,
  setCart,
  checkoutDispatch,
  fulfillmentOptions,
  fulfillmentGroup,
  updateFulfillmentError,
  updateFulfillmentGroup,
  active,
  goToNextStep,
  selectedFulfillmentOption
}) {
  return async (values, actions) => {
    if (!selectedFulfillmentOption) {
      return;
    }
    submitFulfillmentGroup({
      pricedFulfillmentOption: selectedFulfillmentOption,
      updateFulfillmentGroup,
      fulfillmentGroup,
      cart
    })
      .then(data => {
        if (isEmpty(data)) {
          return;
        }
        setCart(data);
        goToNextStep();
      })
      .catch(error => {
        setFetchDeliveryOptionsFailed(checkoutDispatch, true);
      })
      .finally(() => {
        actions.setSubmitting(false);
      });
  };
}

/**
 *
 * @param {FulfillmentOption} fulfillmentOption
 * @param {function} updateFulfillmentGroup
 * @param {Object} fulfillmentGroup
 */
const submitFulfillmentGroup = ({
  fulfillmentGroup,
  updateFulfillmentGroup,
  pricedFulfillmentOption,
  cart
}) => {
  const type = pricedFulfillmentOption.fulfillmentType;
  const { referenceNumber, address, purchaseOrderNumber } = fulfillmentGroup;
  const { attributes } = cart;
  const payload = {
    referenceNumber,
    type,
    address,
    purchaseOrderNumber,
    pricedFulfillmentOption,
    attributes: {
      COMMUNICATION_PREF: attributes?.COMMUNICATION_PREF,
      ORDER_INSTRUCTIONS: attributes?.ORDER_INSTRUCTIONS
    }
  };
  return updateFulfillmentGroup({
    method: 'patch',
    data: payload
  });
};

/**
 * @typedef FulfillmentOption
 * @property {string} serviceLevel
 * @property {string} fulfillmentType
 * @property {string} description
 * @property {Array<string>} calculatorIds
 * @property {Array<string>} bandFields
 * @property {string} fulfillmentReference
 * @property {number} estimatedMinDaysToFulfill
 * @property {number} estimatedMaxDaysToFulfill
 * @property {MonetaryAmount} price
 * @property {boolean} taxable
 * @property {string} taxCode
 * @property {Object} additionalAttributes
 * @property {Array<Object>} productFulfillmentFees
 * @property {MonetaryAmount} basePrice
 */

/**
 * @typedef DealerDeliverySettings
 * @param {boolean} deliveryEnabled
 * @param {String} distanceUnit
 * @param {number} deliveryRadius
 * @param {Object} currencyUnit
 * @param {boolean} orderMinimum
 * @param {number} orderMinimumAmount
 * @param {String} dimensionUnit
 * @param {boolean} dimensionLimit
 * @param {number} dimensionLimitAmount
 * @param {boolean} weightLimit
 * @param {number} weightLimitAmount
 * @param {String} weightUnit
 */

/**
 * @typedef DeliveryValidationRadiusResponse
 * @param {boolean} failedToDetermineDistance
 * @param {String} failureReason
 * @param {Object} unit
 * @param {number} distance
 */

/**
 * @typedef DeliveryValidationResponseItem
 * @param {String} sku
 * @param {boolean} meetsWeightCriteria
 * @param {boolean} meetsDimensionCriteria
 * @param {boolean} eligibleForDelivery
 */

/**
 * @typedef DeliveryValidationFailure
 * @param {boolean} dealerAllowsDelivery
 * @param {boolean} meetsOrderMinimumAmount
 * @param {boolean} inDeliveryRadius
 * @param {boolean} itemsEligibleForDelivery
 * @param {boolean} preferredDeliveryCustomer
 * @param {String} fulfillmentType
 * @param {number} estimatedMinDaysToFulfill
 * @param {number} estimatedMaxDaysToFulfill
 * @param {DealerDeliverySettings} deliverySettings
 * @param {DeliveryValidationRadiusResponse} validationRadiusResponse
 * @param {Array<DeliveryValidationResponseItem>} items
 */

export const FulfillmentOptions = withRouter(FulfillmentOptionsSection);
