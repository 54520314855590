/**
 * Build the site title for search and category pages.
 *
 * Brand & Category Selected: [Brand] [Category] | [Site Title]
 * Category Selected: [Category] | [Site Title]
 * Brand Selected: [Brand] | [Site Title]
 * Nothing: Parts Search | [Site Title]
 *
 * @param {Object} activeTopBrand The active Top Brand, if selected. Nullable.
 * @param {string} categoryName The name of the current category. Will be "Parts Search" if searching the root of the catalog. Will be null for search results.
 * @param {string} categoryMetaTitle The category's meta title.
 * @param {string} siteTitle The site title from the application theme. See useHeaderMetadata.
 * @param {boolean} rootsOnly Indicate if this is a search from the root of the catalog with no category selected. True if there is no category selected, false if the user is searching within a category. Defaults to false.
 * @returns {string} The title for the category or search page.
 */
function buildMetaTitle(
  activeTopBrand,
  categoryName,
  categoryMetaTitle,
  siteTitle,
  rootsOnly = false
) {
  let brandTitle = '';
  if (activeTopBrand) {
    if (activeTopBrand.metaTitle) {
      brandTitle = titleCase(activeTopBrand.metaTitle);
    } else {
      brandTitle = titleCase(activeTopBrand.brandName);
    }
  }

  let categoryTitle = '';
  if (categoryMetaTitle && categoryMetaTitle !== categoryName) {
    categoryTitle = titleCase(categoryMetaTitle);
  } else {
    categoryTitle = titleCase(categoryName);
  }

  let brandSegment = '';
  let categorySegment = '';
  if (activeTopBrand) {
    brandSegment = brandTitle;

    if (!rootsOnly) {
      categorySegment = categoryTitle;
    }
  } else {
    categorySegment = categoryTitle;
    if (!categorySegment) {
      categorySegment = 'Parts Search';
    }
  }

  return `${brandSegment} ${categorySegment} | ${siteTitle}`
    .replaceAll(/\s{2,}/g, ' ') // replace multiple spaces with a single space
    .trim();
}

/**
 * Build the meta description for category and search pages.
 * This will substitute [BRAND], [CATEGORY], and [SITE_TITLE] strings within the Top Brand or Category meta descriptions with appropriate values.
 *
 * @param {Object} activeTopBrand The active Top Brand, if selected. Nullable.
 * @param {string} categoryName The name of the current category. Will be "Parts Search" if searching the root of the catalog. Will be null for search results.
 * @param {string} categoryMetaDescription The category's meta description. Will be used if there is not a brand meta description.
 * @param {string} siteTitle The site title from the application theme. See useHeaderMetadata.
 * @param {string} defaultTemplate The default message template to use for the meta description.
 * @param {boolean} rootsOnly Indicate if this is a search from the root of the catalog with no category selected. True if there is no category selected, false if the user is searching within a category. Defaults to false.
 * @returns {string} The meta description for the category or search page.
 */
function buildMetaDescription(
  activeTopBrand,
  categoryName,
  categoryMetaDescription,
  siteTitle,
  defaultTemplate,
  rootsOnly = false
) {
  let template = defaultTemplate;

  if (activeTopBrand && activeTopBrand.metaDescription) {
    template = activeTopBrand.metaDescription;
  } else if (categoryMetaDescription) {
    template = categoryMetaDescription;
  }

  const brand = activeTopBrand ? activeTopBrand.brandName : '';

  return template
    .replaceAll('[BRAND]', titleCase(brand))
    .replaceAll('[CATEGORY]', rootsOnly ? '' : titleCase(categoryName))
    .replaceAll('[SITE_TITLE]', siteTitle)
    .replaceAll(/\s{2,}/g, ' ') // replace multiple spaces with a single space
    .trim();
}

function titleCase(str) {
  if (!str) {
    return str;
  }

  return str
    .split(' ')
    .map(word => word[0].toUpperCase() + word.substr(1).toLowerCase())
    .join(' ');
}

export { buildMetaTitle, buildMetaDescription };
