/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React, { useContext, useEffect, useMemo } from 'react';
import { isEmpty, isString } from 'lodash';
import { useHistory, withRouter } from 'react-router-dom';

import { CampaignContext, TopBrandContext } from 'app/common/contexts';
import { useFormatMessage, useReadTopBrandsApi } from 'app/common/hooks';
import { Icon, TertiaryButton } from 'app/common/components';

import Dropdown from '../Dropdown';
import messages from './TopBrandsFilter.messages';
import useTopBrandState from 'app/common/hooks/asset/useTopBrandState';
import { Environment } from 'app/common/services';

const featuredBrandsEnabled =
  Environment.get('FEATURED_BRANDS_ENABLED') === 'true';

const TopBrandsFilter = ({ history }) => {
  const formatMessage = useFormatMessage();
  const { response } = useReadTopBrandsApi();
  const { activeTopBrand, setActiveTopBrand, setAllTopBrands, topBrands } =
    useContext(TopBrandContext);
  const { stripBrandFromPath } = useTopBrandState();
  useFilterTopBrands();

  useEffect(() => {
    if (response) {
      setAllTopBrands(response);
    }
    // eslint-disable-next-line
  }, [response]);

  const { topBrandForPath, pathToUse, isCampaign, campaignPath } =
    useHandleCampaignPath();

  if (isEmpty(topBrands) || featuredBrandsEnabled) {
    return null;
  }

  return (
    <div className="tw-flex tw-flex-col tw-items-center md:tw-items-start md:tw-flex-row">
      <aside className="flex items-center justify-start sm:my-2 lg:mx-2">
        <div
          id="top-brand-selector-label"
          className="mr-2 font-bold leading-loose"
        >
          {formatMessage(messages.searchLabel)}
        </div>
        <Dropdown hoverable>
          <Dropdown.Menu.Trigger
            aria-labelledby="top-brand-selector-label top-brand-select-trigger"
            id="top-brand-select-trigger"
            triggerClassName="pl-1 pr-2 text-primary-600 font-medium rounded hover:text-primary-800
            active:text-primary-600 focus:outline-none focus:shadow-outline
            min-w-160px"
          >
            <span>
              {isEmpty(topBrandForPath)
                ? formatMessage(messages.placeholder)
                : topBrandForPath.brandName}
            </span>
          </Dropdown.Menu.Trigger>
          <Dropdown.Menu
            aria-labelledby="top-brand-selector-label"
            style={{
              maxHeight: '300px',
              overflowY: 'scroll'
            }}
          >
            {topBrands.map((option, i) => {
              const { brandName, id } = option;
              const active = pathToUse.startsWith(option.brandPath + '/');
              return (
                <Dropdown.Menu.Item
                  className="text-justify min-w-130px"
                  isActive={active}
                  key={id}
                  onClick={() => {
                    selectActiveTopBrand(
                      option,
                      activeTopBrand,
                      setActiveTopBrand,
                      history,
                      null,
                      isCampaign ? campaignPath : null,
                      isCampaign ? pathToUse : null
                    );
                  }}
                >
                  <span>
                    {isString(brandName) ? brandName : formatMessage(brandName)}
                  </span>
                </Dropdown.Menu.Item>
              );
            })}
          </Dropdown.Menu>
        </Dropdown>
      </aside>

      <aside className="flex items-center justify-start sm:my-2 lg:mx-2">
        {!isEmpty(activeTopBrand) && (
          <TertiaryButton
            onClick={() => {
              selectActiveTopBrand(
                null,
                null,
                setActiveTopBrand,
                history,
                stripBrandFromPath,
                isCampaign ? campaignPath : null,
                isCampaign ? pathToUse : null
              );
            }}
          >
            <Icon className="mr-2" name="times" />
            <div className="leading-none">
              {formatMessage(messages.noTopBrandOptionLabel)}
            </div>
          </TertiaryButton>
        )}
      </aside>
    </div>
  );
};

/**
 * Paths that we let search results trigger filtering.
 * @type {string[]}
 */
const FILTERED_PATHS = ['part-search', 'search'];
function useFilterTopBrands() {
  const { filterBrands } = useContext(TopBrandContext);
  const { location } = useHistory();
  // If location changes, check if we should reset the top brand filter.
  // We don't do this on search related pages, as we will be filtering based on facets. See
  // useCatalogSearchApi
  useEffect(() => {
    const path = location.pathname;
    const splitPath = path.split('/');

    const isFilteredPath = splitPath.some(pathPart =>
      FILTERED_PATHS.includes(pathPart)
    );
    if (!isFilteredPath) {
      filterBrands(undefined);
    }
    // eslint-disable-next-line
  }, [location]);
}

const selectActiveTopBrand = function (
  selectedTopBrand,
  previousTopBrand,
  setActiveTopBrand,
  history,
  stripBrandFromPath,
  campaignUrl,
  pathToUse
) {
  const { location, push } = history;
  let path = pathToUse ? pathToUse : location.pathname;
  path = campaignUrl ? path.replace(campaignUrl, '') : path;

  // Top brand deselected
  if (!selectedTopBrand && !previousTopBrand) {
    path = stripBrandFromPath(path);
    setActiveTopBrand(undefined);
  } else {
    // Previous top brand was set, remove that from path.
    if (previousTopBrand) {
      path = path.replace(previousTopBrand.brandPath, '');
    }
    // Top brand was selected
    if (selectedTopBrand) {
      path = selectedTopBrand.brandPath + path;
    }
    if (setActiveTopBrand) {
      setActiveTopBrand(selectedTopBrand);
    }
  }
  push({
    ...location,
    pathname: path
  });
};

function useHandleCampaignPath() {
  const { location } = useHistory();

  const { activeTopBrand, setActiveTopBrand, allTopBrands } =
    useContext(TopBrandContext);
  const { topBrandMatchesPath, findTopBrandForPath } = useTopBrandState();
  const { targetPage: campaignTargetPage } = useContext(CampaignContext);

  const { currentPath, isCampaign } = useMemo(() => {
    const currentPath = location.pathname;
    const isCampaign = currentPath.startsWith('/_cp');
    return { currentPath, isCampaign };
  }, [location]);

  const topBrandForPath = useMemo(() => {
    return findTopBrandForPath(
      isCampaign && campaignTargetPage ? campaignTargetPage : currentPath,
      allTopBrands
    );
  }, [
    currentPath,
    allTopBrands,
    campaignTargetPage,
    findTopBrandForPath,
    isCampaign
  ]);

  useEffect(() => {
    const path =
      isCampaign && campaignTargetPage ? campaignTargetPage : currentPath;
    if (topBrandMatchesPath(path, activeTopBrand)) {
      return;
    }
    if (topBrandForPath) {
      setActiveTopBrand(topBrandForPath);
    } else {
      setActiveTopBrand(null);
    }
    // eslint-disable-next-line
  }, [
    activeTopBrand,
    currentPath,
    topBrandForPath,
    allTopBrands,
    campaignTargetPage,
    isCampaign
  ]);
  const pathToUse = campaignTargetPage ? campaignTargetPage : currentPath;
  return { topBrandForPath, pathToUse, isCampaign };
}

export default withRouter(TopBrandsFilter);
export { TopBrandsFilter };
