/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React from 'react';
import { includes, merge } from 'lodash';
import PropTypes from 'prop-types';

import { PriceContext } from 'app/common/contexts';
import {
  defaultRefreshEffectCondition,
  useCatalogInfo,
  usePriceContextHeader,
  usePriceInfoContextHeader,
  useRefreshEffect,
  useRestApi
} from 'app/common/hooks';
import { logError } from 'app/common/utils/ApiErrorUtils';
import { NotFound } from 'app/layout/components';

import {
  CategoryList,
  DefaultCategoryBrowse,
  DefaultCategoryBrowseSkeleton
} from '../../components';
import useTopBrandState from 'app/common/hooks/asset/useTopBrandState';
import { Environment } from 'app/common/services';

const featuredBrandsEnabled =
  Environment.get('FEATURED_BRANDS_ENABLED') === 'true';

/**
 * Helper component used to both fetch the category matching the current
 * location and determine which category UI component to use to render the
 * data.
 *
 * @visibleName Category Renderer
 * @author [Nathan Moore](https://github.com/nathandmoore)
 */
const CategoryRenderer = ({ location }) => {
  let { pathname } = location;
  const hasFilters = includes(location.search, 'filters');
  const {
    categoryInfo: { routeBaseContext, detailsUrl }
  } = useCatalogInfo();
  const { hasLoadedPriceLists } = React.useContext(PriceContext);
  const priceContextHeader = usePriceContextHeader();
  const priceInfoContextHeader = usePriceInfoContextHeader();
  const { stripBrandFromPath } = useTopBrandState();

  pathname = stripBrandFromPath(pathname.replace('/_cp', ''));
  const rootsOnly = pathname === routeBaseContext;
  const categoryUrl = React.useMemo(() => {
    const url = rootsOnly
      ? routeBaseContext
      : pathname.replace(routeBaseContext, '');
    return stripBrandFromPath(url);
  }, [pathname, routeBaseContext, rootsOnly, stripBrandFromPath]);
  const config = React.useMemo(
    () =>
      merge(
        {
          params: {
            categoryUrl,
            rootsOnly,
            offset: 0,
            forward: true,
            // don't need any products
            size: 0
          }
        },
        priceContextHeader,
        priceInfoContextHeader
      ),
    [categoryUrl, rootsOnly, priceContextHeader, priceInfoContextHeader]
  );
  const {
    exception,
    error,
    loading,
    response,
    sendCallback: fetchCategory
  } = useRestApi(detailsUrl, config, false);

  useRefreshEffect(
    fetchCategory,
    { config, hasLoadedPriceLists },
    refreshCondition,
    hasLoadedPriceLists
  );

  if (error) {
    logError({
      ...exception,
      when: `fetching a Category matching the path ${categoryUrl}`
    });

    return <NotFound />;
  }

  if (loading || response === undefined) {
    return <DefaultCategoryBrowseSkeleton />;
  }

  const { displayTemplate } = response;

  if (isCategoryListPage(rootsOnly, displayTemplate)) {
    return (
      <CategoryList
        category={response}
        rootsOnly={displayTemplate === 'ROOT_CATEGORY_LIST'}
      />
    );
  }

  return <DefaultCategoryBrowse category={response} />;
};

function refreshCondition(dependencies, prevDependencies) {
  return (
    defaultRefreshEffectCondition(dependencies, prevDependencies) &&
    dependencies.hasLoadedPriceLists
  );
}

function isCategoryListPage(rootsOnly, displayTemplate) {
  return (
    ((featuredBrandsEnabled || !rootsOnly) &&
      displayTemplate === 'CATEGORY_LIST') ||
    displayTemplate === 'ROOT_CATEGORY_LIST'
  );
}

CategoryRenderer.propTypes = {
  /** Represents the current location of the app */
  location: PropTypes.shape({
    key: PropTypes.string,
    pathname: PropTypes.string.isRequired,
    search: PropTypes.string,
    hash: PropTypes.string,
    status: PropTypes.object
  }).isRequired
};

export default CategoryRenderer;
export { CategoryRenderer };
