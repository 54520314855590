import {
  useFormatMessage,
  useGtmPageView,
  useToggle
} from 'app/common/hooks/index.js';
import { useLocation } from 'react-router-dom';
import { useHeaderMetadata } from 'app/core/components/App/index.js';
import { Helmet as ReactHelmet } from 'react-helmet';
import React, { useEffect, useState } from 'react';
import messages from './Brands.messages';
import { logError } from 'app/common/utils/ApiErrorUtils/index.js';
import { get, isEmpty } from 'lodash';
import { useAllBrandsApi } from './hooks/index.js';
import {
  BrandsCategoryFilters,
  BrandList,
  BrandSectionLinks
} from './components/index.js';
import { Breadcrumbs } from 'app/common/components/index.js';

const Brands = () => {
  const formatMessage = useFormatMessage();
  const [brands, setBrands] = useState({});
  const breadcrumbs = [
    { uri: '/brands', label: formatMessage(messages.title), preserveCase: true }
  ];

  useAllBrandsApi(setBrands);

  return (
    <main className="container relative flex-grow mx-auto p-4 sm:py-8 md:px-6 lg:px-8">
      <BrandsHelmet />

      <header className="text-center">
        <Breadcrumbs breadcrumbs={breadcrumbs} />
        <div className="flex justify-between items-baseline mb-2 border-b border-gray-400">
          <h1 className="block mb-2 capitalize text-gray-700 text-2xl font-medium leading-tight sm:text-3xl lg:text-4xl">
            {formatMessage(messages.title)}
          </h1>
        </div>
      </header>

      <BrandSectionLinks brandsMap={brands} />
      <BrandList brandsMap={brands} />
    </main>
  );
};

const BrandsHelmet = () => {
  const formatMessage = useFormatMessage();
  const { path } = useLocation();
  const { siteTitle, siteDescription } = useHeaderMetadata();

  const title = formatMessage(messages.title);
  const canonical = `${window.location.origin}${path}`;
  useGtmPageView('Brands');

  return (
    <ReactHelmet titleTemplate={`%s - ${siteTitle}`}>
      <title>{title}</title>
      <meta name="description" content={formatMessage(messages.description)} />
      <meta name="canonical" content={canonical} />
      <meta name="robots" content="index,follow" />
      <meta property="og:title" content={title} />
      <meta property="og:site_name" content={siteTitle} />
      <meta property="og:type" content="website" />
      <meta property="og:description" content={siteDescription} />
      <link rel="canonical" href={canonical} />
    </ReactHelmet>
  );
};

export default Brands;
export { Brands };
