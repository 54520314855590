/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import { useMemo } from 'react';
import { toUpper } from 'lodash';

import { Environment } from 'app/common/services';

const TENANT_ID = toUpper('"5df1363059675161a85f576d"');
const CONTEXT_REQUEST = `contextRequest=%7B"tenantId":"${TENANT_ID}"%7D`;
const BASE_URL = Environment.get(
  'ASSET_BASE_URL',
  'https://localhost:8447/content/'
);

function usePersonalizationInfo() {
  return useMemo(
    () => ({
      about: {
        targeterName: Environment.get('ABOUT_CONTENT_TARGETER', 'ABOUT_US')
      },
      contact: {
        targeterName: Environment.get('CONTACT_CONTENT_TARGETER', 'CONTACT_US')
      },
      privacy: {
        targeterName: Environment.get(
          'PRIVACY_POLICY_CONTENT_TARGETER',
          'PRIVACY_POLICY'
        )
      },
      tou: {
        targeterName: Environment.get('TOU_CONTENT_TARGETER', 'TERMS_OF_USE')
      },
      contentTargeterInfo: {
        fetchUrl: Environment.get(
          'TARGETER_ENDPOINT',
          'personalization/content-targeters/item'
        )
      },
      homepage: {
        guest: {
          targeterName: Environment.get(
            'HOME_GUEST_CONTENT_TARGETER',
            'GUEST_HOME'
          )
        },
        hero: {
          targeterName: Environment.get(
            'HOME_HERO_CONTENT_TARGETER',
            'Homepage Banner Ad'
          ),
          defaultSrc: Environment.get(
            'HOMEPAGE_HERO_SOURCE_URL',
            `${BASE_URL}Site_Banner_May_JB_01_Red_Variant.jpg?${CONTEXT_REQUEST}`
          )
        },
        middlePromo: {
          targeterName: Environment.get(
            'HOME_MIDDLE_PROMO_CONTENT_TARGETER',
            'Homepage Middle Promo'
          )
        }
      },
      layout: {
        header: {
          logoSrc: Environment.get(
            'LAYOUT_HEADER_LOGO_SRC',
            `${BASE_URL}b2b-the-heat-clinic-logo.png?${CONTEXT_REQUEST}`
          )
        },
        headerCallout: {
          targeterName: Environment.get(
            'LAYOUT_HEADER_CALLOUT',
            'Header Callout'
          )
        }
      },
      searchBrowse: {
        startAd: {
          defaultSrc: Environment.get(
            'HOMEPAGE_HERO_SOURCE_URL',
            `${BASE_URL}rhs-ad.jpg?${CONTEXT_REQUEST}`
          ),
          targeterName: Environment.get(
            'SEARCH_BROWSE_LEFT_AD_CONTENT_TARGETER',
            'Search and Browse Left-Side Ad'
          )
        }
      }
    }),
    []
  );
}

export default usePersonalizationInfo;
