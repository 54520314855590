import { map } from 'lodash';

const BrandSectionLinks = ({ brandsMap }) => {
  const brandLetters = Object.keys(brandsMap);

  return (
    <section className="mt-8 flex flex-wrap justify-start gap-x-1 gap-y-2">
      {map(ALPHABET, (letter, key) => (
        <LetterSelection
          letter={letter}
          enabled={brandLetters.includes(letter)}
          key={key}
        />
      ))}
    </section>
  );
};

const LetterSelection = ({ letter, enabled }) => {
  const classes = 'brands-letter px-1 mx-0.5 border border-solid';
  const props = {};
  if (enabled) {
    props.href = '#' + letter;
    props.className = classes + ' text-primary-500';
  } else {
    props.className = classes + ' text-gray-500 cursor-not-allowed';
  }

  return <a {...props}>{letter}</a>;
};

const ALPHABET = getAlphabetArray();

function getAlphabetArray() {
  const alphabetArray = [];
  alphabetArray.push('#');
  // Loop from the Unicode value of 'A' (65) to 'Z' (90)
  for (let i = 65; i <= 90; i++) {
    alphabetArray.push(String.fromCharCode(i));
  }
  return alphabetArray;
}

export default BrandSectionLinks;
export { BrandSectionLinks };
